

obolon.router = (function () {
    var that = this,
        currentPage = window.location.pathname,
        mainLeft = $('body > .main .main_left'),
        mainRight = $('body > .main .main_right'),
        langHead = $('header .langs'),
        router = new Router(); //Instanciate the router

    function start() {
        $('body').on('click', 'a', function (ev) {
            var $link = $(this),
                link = $link.attr('href');

            if (navigate(link)) {
                return false;
            }
        });

        navs();
        // Start the router
        router.start();
    }

    function navigate(link) {
        var isAppRoute = false;

        if(link.indexOf('/ru') == 0) { // ru language
            isAppRoute = true;
        }
        if(link.indexOf('/en') == 0) { // en language
            isAppRoute = true;
        }
        if(link.indexOf('/ua') == 0) { // ua language
            isAppRoute = true;
        }
        if(isAppRoute) {
            router.navigate(link);
            return true;
        }else{
            return false;
        }
    }

    function isCatActive() {
      var cat = $('h1.bread-h1').attr('data-cat');

      if(!_.isUndefined(cat)){
        $('.submenu-link').each(function (i,mnu) {
          var link = $(mnu).find('a');
          if($(link).attr('href').indexOf(cat) > 0){
            $(mnu).addClass('active');
          }
        });

        $('.submenu-one').each(function (i,mnu) {
          var link = $(mnu).find('a');
          if($(link).attr('href').indexOf(cat) > 0){
            $(mnu).find('.submenu-title').addClass('active');
            $(mnu).addClass('active');
          }
        });
      }
    }

    function loadPageFromServer(link) {
        if(currentPage !== link){
            console.log(']=>',currentPage, link);
            $('html,body').animate({scrollTop: 0}, 200);
            if(obolon.debug) console.log('load page: ', link);
            $('header').removeClass('open-submenu');
            mainLeft.parent().addClass('loading');
            var linkArr = link.split('/'),
                getLink = '';
            if (link.indexOf('/ru') > -1) {
                getLink = '/ru/api';
            } else if (link.indexOf('/ua') > -1) {
                getLink = '/ua/api';
            } else if (link.indexOf('/en') > -1) {
                getLink = '/en/api';
            }
            for (var i = 1, n = linkArr.length; i < n; i++) {
                if (linkArr[i] !== '' && linkArr[i] !== 'api' && linkArr[i] !== 'ru' && linkArr[i] !== 'en' && linkArr[i] !== 'ua') {
                    getLink += '/' + linkArr[i];
                }
            }

            $('.head-right .menu-one .submenu-inner .submenu-link').removeClass('active');

            $.get(getLink)
                .done(function (data) {
                    var html = $('<div />').append(data); 
                    // SEO
                    $('head title').text(html.find('title').text());
                    $('head [name="description"]').text(html.find('[name="description"]').text());
                    $('head [name="keywords"]').text(html.find('[name="keywords"]').text());
                    /* ------ */
                    mainLeft.html(html.find('.main_left').html()); // центральный блок
                    mainRight.html(html.find('.main_right').html()); // правый блок
                    langHead.html(html.find('.main_head').html()); // блок с переключением языков

                    obolon.scrFunc(); // показываем скрытые
                    obolon.plaginsInit(); // инит плагинов
                    currentPage = link;

                    isCatActive();
                })
                .fail(function(data) {
                    var status = data.status;
                    if(status == '404') {
                        alert("Page not found");
                    }else{
                        alert("...error");
                    }
                    router.go(-1);
                })
                .always(function (data) {
                    var html = $('<div />').append(data);
                    if(obolon.debug) console.log(html);
                    setTimeout(function () {
                        mainLeft.parent().addClass('load');
                        setTimeout(function () {
                            mainLeft.parent().removeClass('loading load');
                        }, 500);
                    }, 400);
                });
        }else{
            if(obolon.debug) console.warn('page "'+currentPage+'" will be loaded');
        }
    }

    function closeMenu() {
        $('header').scrollTop(0);
        $('header').removeClass('open');
        $('body').removeClass('stop-scroll');
    }

    function navs() {
        if(obolon.debug) console.log(currentPage,'<<');
        router.route('/:lang', function (lang) {
            loadPageFromServer('/'+lang);
            $('.desktop-menu .nav-a').removeClass('active');
            $('.head-right .menu-one .submenu-one').removeClass('active');
            $('.head-right .menu-one .submenu-title').removeClass('active');

            closeMenu();
        });

        router.route('/:lang/*path', function (lang,path,q) {
            var path = path.split('#')[0],
                query = (q)?'?'+q:'',
                link = '/'+lang+'/'+path+query;
            if(obolon.debug) console.info(link);
            loadPageFromServer(link);
            closeMenu();
            isCatActive();
        });
        router.route('/:lang/:category', function (lang, cat) {
            var catIndex = null;
            $('.desktop-menu .nav-a').removeClass('active');
            $('.head-right .menu-one .submenu-one').removeClass('active');
            $('.head-right .menu-one .submenu-title').removeClass('active');
            /* active по разделам */
            $('.desktop-menu .nav-a').each(function (i,mnu) {
                var curIndex = $(mnu).attr('data-index');
                if($(mnu).attr('href').indexOf(cat) > 0 && curIndex < 6){
                    $(mnu).addClass('active');
                    catIndex = $(mnu).attr('data-index');
                }
            });

            if(!catIndex) {
                $('.desktop-menu .nav-a').removeClass('active');
            }
            closeMenu();
        });

        router.route('/:lang/:category/:subcategory', function (lang, cat, sub) {
            var catIndex = null;
            $('.desktop-menu .nav-a').removeClass('active');
            $('.head-right .menu-one .submenu-one').removeClass('active');
            $('.head-right .menu-one .submenu-one .submenu-link').removeClass('active');
            $('.head-right .menu-one .submenu-title').removeClass('active');

            /* active по разделам */
            $('.desktop-menu .nav-a').each(function (i,mnu) {
                if($(mnu).attr('href').indexOf(cat) > 0){
                    console.log('cat:::'+cat);
                    console.log('subcat:::'+sub);
                    console.log(mnu);
                    catIndex = $(mnu).attr('data-index');
                    $(mnu).addClass('active');
                }
            });

            if(catIndex) {
                /* active по подразделам */
                $('.head-right .menu-one').each(function (i, sb) {
                    if ($(sb).attr('data-index') == catIndex) {
                        $(sb).find('.submenu-title').each(function (j, tit) {
                            if ($(tit).find('a').attr('href').indexOf(sub) > 0) {
                                $(tit).parent().addClass('active');
                                $(tit).addClass('active');
                            }
                        });
                    }
                });
            } else {
                $('.desktop-menu .nav-a').removeClass('active');
            }

            setTimeout(function () {
              $('.phone-content-inner a').each(function (i,mnup) {
                if ($(mnup).attr('href').indexOf(sub) > 0) {
                  $(mnup).addClass('active');
                }
              });
            },1000);

            closeMenu();
        });

        router.route('/:lang/:category/:subcategory/:subsubcategory', function (lang, cat, sub, subsub) {
          var catIndex = null;
            /* active по разделам */
            $('.desktop-menu .nav-a').each(function (i,mnu) {
                if($(mnu).attr('href').indexOf(cat) > 0){
                    catIndex = $(mnu).attr('data-index');
                    $(mnu).addClass('active');
                }
            });

            ////////////////////////////////////////////////////////////////////
            $('.head-right .menu-one').each(function (i, sb) {
              if ($(sb).attr('data-index') == catIndex) {
                $(sb).find('.submenu-title').each(function (j, tit) {
                  if ($(tit).find('a').attr('href').indexOf(sub) > 0) {
                    $(tit).parent().addClass('active');
                    $(tit).addClass('active');
                  }
                });
              }
            });

            $('.phone-content-inner a').each(function (i,mnup) {
              if ($(mnup).attr('href').indexOf(sub) > 0) {
                $(mnup).addClass('active');
              }
            });
            ////////////////////////////////////////////////////////////////////

            /* active по подразделам */
            $('.head-right .submenu-one').removeClass('active');
            $('.head-right .submenu-link').removeClass('active');
            $('.head-right .submenu-link').each(function (i, link) {
                if ($(link).find('a').attr('href') == '/'+lang+'/'+cat+'/'+sub+'/'+subsub) {
                    $(link).parent().addClass('active');
                    $(link).addClass('active');
                }
            });
            closeMenu();
        });

        router.route('/:lang/production/:type', function (lang, type) {
            if(obolon.debug) console.log('<<-->>', lang, type);
            closeMenu();
        });

        router.route('/:lang/production/:type/:brand', function (lang, type, brand) {
            if(obolon.debug) console.log('<<-->>', lang, type, brand);
            closeMenu();
        });

        router.route('/:lang/about/*path', function (lang,path) {
            if(obolon.debug) console.log(lang,path);
            closeMenu();
        });


        router.route('', function () {
            if(obolon.debug) console.log("default route")
            closeMenu();
        });
    }

    return {
        init: function () {
            start();
        },
        nav: function (link) {
            navigate(link);
        }
    }
}());
