$(function () {
    
    $(window)
        .on('load', function(){
            obolon.scrFunc();
            obolon.scrBann($(this));
            obolon.loadFunc();
        })
        .on('scroll', function(){
            obolon.scrFunc();
            obolon.scrBann($(this));
        })
        .on('resize', function(){
            obolon.resizeFunc();
        });

    obolon.router.init();


  function ValidMail(emailAddress) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
  }

  function ValidPhone(tel) {
    //var pattern = new RegExp(/^[+]\d[\d\(\)\ -]{4,14}\d$/);
    //return pattern.test(tel);
	return tel;
  }
  
  $('input#phone').inputmask("+38 (099) 999-99-99");

  $('body')
       .on('mouseenter','header .nav-a > *', function(){
            var _this = $(this).parent(),
                _head = $('header'),
                _ind = _this.data('index'),
                _subMenu = $('.head-right .menu-one[data-index="' + _ind + '"]');

            $('.head-right .menu-one').removeClass('active');
            _head.find('.nav-a').removeClass('hover');
            if(_subMenu.length){
                _head.addClass('open-submenu');
                _this.addClass('hover');
                $('.head-right .menu-one[data-index="' + _ind + '"]').addClass('active');
            }else{
                _head.removeClass('open-submenu');
            }
        })
        .on('click','header .nav-a', function(){
            console.log('vxcv');
            var _this = $(this),
                _head = $('header'),
                _ind = _this.data('index'),
                _subMenu = $('.head-right .menu-one[data-index="' + _ind + '"]');

            $('.head-right .menu-one').removeClass('active');
            _head.find('.nav-a').removeClass('hover');
            if(_subMenu.length){
                _head.addClass('open-submenu');
                _this.addClass('hover');
                $('.head-right .menu-one[data-index="' + _ind + '"]').addClass('active');
            }else{
                _head.removeClass('open-submenu');
            }
        })
       .on('mouseenter','.hdbg', function(){
            $('header').removeClass('open-submenu');
            $('header .nav-a').removeClass('hover');
            $('.head-right .menu-one').removeClass('active');

        })
        .on('click','header .phone-icon', function(){
            if($('header').hasClass('open')) {
                $('header').scrollTop(0);
                $('header').removeClass('open');
                $('body').removeClass('stop-scroll');
            } else {
                $('header').addClass('open');
                $('body').addClass('stop-scroll');
            }
        })
        .on('click','header .phone-menu-content a', function(){
            $('header .phone-menu-content a').removeClass('active');
            $(this).addClass('active');

            if($('header').hasClass('open')) {
                $('header').scrollTop(0);
                $('header').removeClass('open');
                $('body').removeClass('stop-scroll');
            } else {
            }
        })
        .on('click','.alco-info .alco-close', function(){
            $('.alco-info').addClass('close');
            $.post('/informers', {type: 'alco'});
            setTimeout(function () {
                $('.alco-info').remove();
            },500);
        })
        .on('click','.plus18 .yes', function(){
            $('.plus18').addClass('hide');
            $.post('/informers', {type: '18plus'});
            setTimeout(function () {
                $('.plus18').remove();
            },600);
        })
        .on('click','.plus18 .no', function(){
            $('.plus18 .inn18').addClass('hide');
            $('.plus18 .no-block').addClass('show');
        })
        .on('submit','.ajax-form', function(){
            var $form = $(this);
            var errs = 0;

            console.log('foooorm');

            $form.find('.one-wrap').removeClass('error');

            var tel = $('input#phone').val();
            if(!ValidPhone(tel)){
              errs++;
              $('input#phone').parent().addClass('error');
            }

            var email = $('input#email').val();
            if(!ValidMail(email)){
              errs++;
              $('input#email').parent().addClass('error');
            }

            if(errs == 0){
                $.post($form.attr('action'),$form.serialize())
                .done(function (data) {
                    $form[0].reset();
                    var text = $('#choose .upload-btn__txt').attr('data-text');
                    $('#choose .upload-btn__txt').text(text);
                    $form.find('.send-form').addClass('success');
                    if (typeof $('#recaptcha') != "undefined") { grecaptcha.reset(); }


                    setTimeout(function () {
                        $form.find('.send-form').removeClass('success');
                    }, 1200);
                })
                .fail(function (data) {
                    var err;
                    if (typeof $('#recaptcha') != "undefined") { grecaptcha.reset(); }

                  if(data.responseText){
                        err = JSON.parse(data.responseText);
                        if(obolon.debug) console.warn(err);
                    }
                    if(_.isObject(err)){
                        _.forEach(err, function(value, key) {
                            //$form.find('[name="'+key+'"]').parent().append('<i class="err">'+value[0]+'</i>');
                            $form.find('[name="'+key+'"]').parent().addClass('error');
                        });
                    }
                });
            }else{
              if (typeof $('#recaptcha') != "undefined") { grecaptcha.reset(); }
            }
            return false;
        })
        .on('submit','#filterNews', function(){
            var $form = $(this),
                data = $form.serialize().split('&'),
                year = data[0].split('=')[1] || 0,
                month = data[1].split('=')[1] || 0,
                address = (year==0 && month==0)?'':'/filter/'+year+'/'+month;
            obolon.router.nav($form.attr('action')+address);
            return false;
        })
        .on('submit','#formSearch', function(){
            var $form = $(this),
                formLink = $form.attr('action'),
                query = $form.find('input').val();
            obolon.router.nav(formLink+query);
            return false;
        })
        .on('click', '.media-photo-one', function() {
            var imagesArr = $(this).closest('.page-one').find('.media-gallery').text().split('|'),
                images = [];
            console.log(imagesArr);
            for(var i=0,cnt=imagesArr.length; i<cnt; i++){
                images.push({
                    'src': '/img/gallery/'+imagesArr[i],
                    'thumb': '/img/gallery/preview/'+imagesArr[i]
                })
            }
            if(obolon.debug) console.log(images);
            $(this).lightGallery({
                // mode: 'lg-zoom-in-out',
                mode: 'lg-tube',
                dynamic: true,
                counter: false,
                download: false,
                thumbnail: true,
                thumbMargin: 0,
                toogleThumb: false,
                fullScreen: false,
                zoom: false,
                hash: false,
                autoplayControls: false,
                animateThumb: true,
                dynamicEl: images
            });

            return false;
        });

        $('.korpor-map').scrollLeft(150);

        $('header .langs a.active').click(function (event) {
            event.preventDefault();
            return false;
        });
		
		
		var intViewportHeight = window.innerHeight;
		console.log(intViewportHeight);
		
		if(intViewportHeight <= 500) {
			
			$('.menu-one-wrap').css({'overflow-y':'scroll', 'height': '100%'});
			
		}

});
